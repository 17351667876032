import React from "react"
import PropTypes from "prop-types"
import Box from "./box"
import { keyframes } from "@emotion/react"

const pulse = keyframes({
  "0%": { transform: "scale(0)" },
  "100%": { transform: "scale(1)", opacity: "0" },
})

/**
 * Pulse animation to use as loading state
 * loosely based on [spinkit](https://github.com/tobiasahlin/SpinKit)
 */
const LoadingPulse = React.forwardRef(({ color }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        display: "inline-flex",
        width: 12,
        height: 12,
        bg: color,
        borderRadius: "9999em",
      }}
      css={{
        animationName: pulse.toString(),
        animationTimingFunction: "ease-in-out",
        animationDuration: "1000ms",
        animationDelay: "0s",
        animationIterationCount: "infinite",
        animationFillMode: "both",
      }}
    />
  )
})

LoadingPulse.displayName = "LoadingPulse"

LoadingPulse.propTypes = {
  color: PropTypes.string,
}

LoadingPulse.defaultProps = {
  color: "primary",
}

export default LoadingPulse
