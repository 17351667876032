import React, { useEffect, useState } from "react"
import { useThemeUI } from "theme-ui"
import Box from "../../components/box"
import Dialog from "../../components/dialog"
import Stack from "../../components/stack"
import Grid from "../../components/grid"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Icon from "../../components/icon"
import Text from "../../components/text"

const SeasonButton = ({ season, selectedSeason, children, onSelect }) => {
  const context = useThemeUI()
  const { theme } = context
  const isSelected = selectedSeason === season

  return (
    <Box
      as="button"
      sx={{
        bg: isSelected ? season : "padInactive",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "background",
        color: "black",
        borderRadius: "lg",
        p: 3,
        fontFamily: "sans",
        fontWeight: "bold",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        boxShadow: isSelected ? `0 0 0 4px ${theme.colors["contrast"]}` : null,
        alignItems: "center",
        variant: "buttons.pads",
        ":focus": {
          outline: 0,
          boxShadow: `0 0 0 4px ${theme.colors["contrast"]}`,
        },
      }}
      onClick={(event) => {
        event.preventDefault()
        onSelect(season)
      }}
    >
      {children}
    </Box>
  )
}

const SelectButton = ({ color, isSelected, children, onClick }) => {
  const context = useThemeUI()
  const { theme } = context

  return (
    <Box
      as="button"
      onClick={(e) => onClick(e)}
      sx={{
        appearance: "none",
        borderRadius: "lg",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "background",
        boxShadow: isSelected ? `0 0 0 4px ${theme.colors["contrast"]}` : null,
        p: 3,
        width: "100%",
        bg: isSelected ? color : "transparent",
        textAlign: "left",
        overflow: "hidden",
        variant: "buttons.pads",
        "&:hover, &:focus": isSelected ? null : { bg: "muted" },
        ":focus": {
          outline: 0,
          boxShadow: `0 0 0 4px ${theme.colors["contrast"]}`,
        },
      }}
    >
      <Columns alignY="center">
        <Column>
          <Text sans color={isSelected ? "black" : null}>
            {children}
          </Text>
        </Column>
        <Column width={12}>
          {isSelected ? <Icon icon="done" color="black" /> : null}
        </Column>
      </Columns>
    </Box>
  )
}

const SoundSelectionModal = ({
  selectedPad,
  sounds,
  selectedSound,
  selectedSeason,
  onChangeSelection,
  ...restProps
}) => {
  const [seasonToggle, setSeasonToggle] = useState(selectedSeason)

  useEffect(() => {
    if (selectedSeason) {
      setSeasonToggle(selectedSeason)
    } else {
      setSeasonToggle("spring")
    }
  }, [selectedSeason, setSeasonToggle])

  return (
    <Dialog
      {...restProps}
      title={`Sound für Pad ${selectedPad + 1}`}
      align="top"
    >
      <Stack space={6}>
        <Grid as="ul" columns={[2, 4]} space={2}>
          <SeasonButton
            season="spring"
            selectedSeason={seasonToggle}
            onSelect={(season) => {
              setSeasonToggle(season)
            }}
          >
            Frühling
          </SeasonButton>
          <SeasonButton
            season="summer"
            selectedSeason={seasonToggle}
            onSelect={(season) => {
              setSeasonToggle(season)
            }}
          >
            Sommer
          </SeasonButton>
          <SeasonButton
            season="autumn"
            selectedSeason={seasonToggle}
            onSelect={(season) => {
              setSeasonToggle(season)
            }}
          >
            Herbst
          </SeasonButton>
          <SeasonButton
            season="winter"
            selectedSeason={seasonToggle}
            onSelect={(season) => {
              setSeasonToggle(season)
            }}
          >
            Winter
          </SeasonButton>
        </Grid>
        <Stack space={2}>
          {sounds[seasonToggle]?.map((sound) => (
            <SelectButton
              key={sound.soundId}
              color={seasonToggle}
              isSelected={sound.soundId === selectedSound}
              onClick={(event) => {
                event.preventDefault()
                onChangeSelection({
                  season: seasonToggle,
                  soundId: sound.soundId,
                })
              }}
            >
              {sound.title}
            </SelectButton>
          ))}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default SoundSelectionModal
