import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { keyframes } from "@emotion/react"
import Box from "./box"

const SoundPadProgress = ({ duration, currentClick, ...restProps }) => {
  const [transform, setTransform] = useState(null)

  useEffect(() => {
    if (currentClick) {
      setTransform(false)
    }
  }, [currentClick, setTransform])

  useEffect(() => {
    if (transform !== null && !transform) {
      setTransform(true)
    }
  }, [transform, setTransform])

  const scale = keyframes({
    from: {
      transform: "scaleX(0)",
    },
    to: {
      transform: "scaleX(1.05)",
    },
  })

  return transform ? (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        bg: "rgba(255,255,225,.15)",
        width: "100%",
        transform: "scaleX(0)",
      }}
      css={{
        transformOrigin: "left",
        animationName: scale.toString(),
        animationTimingFunction: "linear",
        animationDuration: `${duration}ms`,
      }}
      {...restProps}
    />
  ) : (
    <></>
  )
}

SoundPadProgress.propTypes = {
  currentClick: PropTypes.number,
  duration: PropTypes.number,
}

SoundPadProgress.defaultProps = {
  currentClick: null,
  duration: 0,
}

export default SoundPadProgress
