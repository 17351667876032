import React from "react"
import PropTypes from "prop-types"
import ReactModal from "react-modal"
import { css, Global, ClassNames } from "@emotion/react"
import { useThemeUI } from "theme-ui"
import Box from "./box"
import Divider from "./divider"
import Heading from "./heading"
import SquareButton from "./square-button"
import Stack from "./stack"

const Dialog = ({
  align,
  children,
  title,
  footer,
  onAfterOpen,
  isOpen,
  onClose,
  contentLabel,
}) => {
  ReactModal.setAppElement("#___gatsby")

  const context = useThemeUI()
  const { theme } = context
  const background = theme.colors.modal

  const ReactModalGlobalStyles = () => (
    <Global
      styles={css`
        .ReactModal__Body--open {
          overflow: hidden;
        }
      `}
    />
  )

  const styleStrings = {
    modalBody: `
    width: 36rem;
    max-width: 85vw;
    max-height: 85vh;
    align-self: ${
      align === "top"
        ? "flex-start"
        : align === "bottom"
        ? "flex-end"
        : "center"
    };
    
    &:focus {
      outline: none;
    }
  `,
    overlay: `
    z-index: ${theme.zIndices["60"]};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: ${background};
  `,
    overlayAfterOpen: `
    background-color: ${background};
    opacity: 1;
  `,
    overlayBeforeClose: `
    background-color: ${background};
    opacity: 0;
  `,
  }

  return (
    <>
      <ReactModalGlobalStyles />
      <ClassNames>
        {({ css, cx }) => (
          <ReactModal
            className={{
              base: cx(
                css`
                  ${styleStrings.modalBody};
                `
              ),
              afterOpen: "",
              beforeClose: "",
            }}
            overlayClassName={{
              base: css`
                ${styleStrings.overlay};
              `,
              afterOpen: css`
                ${styleStrings.overlayAfterOpen};
              `,
              beforeClose: css`
                ${styleStrings.overlayBeforeClose};
              `,
            }}
            onAfterOpen={onAfterOpen}
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel={contentLabel}
          >
            <Box
              py={12}
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  bg: "background",
                  borderWidth: 4,
                  borderStyle: "solid",
                  borderColor: "contrast",
                  width: "100%",
                }}
              >
                <Box
                  p={[6, 8]}
                  sx={{
                    position: "relative",
                  }}
                >
                  {!footer && (
                    <Box position="absolute" right={-4} top={-4} zIndex="60">
                      <SquareButton
                        icon="close"
                        onClick={onClose}
                        aria-label="Schließen"
                      />
                    </Box>
                  )}
                  <Stack space={[6, 8]}>
                    {title && (
                      <Stack space={3}>
                        <Heading level={5}>{title}</Heading>
                        <Divider size={2} />
                      </Stack>
                    )}
                    {children}
                  </Stack>
                </Box>
                {footer && (
                  <Box px={[6, 8]} pb={[6, 8]}>
                    {footer}
                  </Box>
                )}
              </Box>
            </Box>
          </ReactModal>
        )}
      </ClassNames>
    </>
  )
}

Dialog.propTypes = {
  align: PropTypes.string,
}

Dialog.defaultProps = {
  align: "center",
}

export default Dialog
